import { Box, styled } from '@mui/material';

export const StyledBusDisplayContainer = styled(Box)`
    width: 1920px;
    height: 700px;
    position: relative;
    font-family: 'Helvetica Neue', sans-serif;
`;

export const StyledBusDisplayContentContainer = styled(Box)`
    height: 300px;
    background-color: #1d569f;
`;

export const StyledNoActiveFlight = styled(Box)`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 150px;
`;
