import { Box, styled } from '@mui/material';

export const StyledBusDisplayFooterContainer = styled(Box)`
    padding-right: 60px;
    background-color: white;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: flex-start;
    padding-top: 20px;
    font-size: 30px;
    font-weight: bold;
    color: #929297;
`;
